<template>
  <div class="widgets content has-text-centered">
    <div class="widgets-shorts">
      <v-row>
        <v-col
          v-for="(item, idx) in shorts"
          :key="item.title"
          lg="4"
          md="4"
          sm="6"
          xs="6"
          cols="6"
        >
          <v-card>
            <v-card-text class="d-flex justify-space-between align-center">
              <router-link class="d-flex" style="flex: 1 1 auto" :to="item.route">{{ item.title }}</router-link>
              <v-icon>{{ item.icon }}</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="widgets__grid">
      <WidgetCard
        :name="item.name"
        v-for="item in items"
        :key="item.name"
      />
    </div>
  </div>
</template>

<script>
import WidgetCard, {
  CARD_DOCTORS,
  CARD_PAGES,
  CARD_POSTS,
} from '@/components/Widget/WidgetCard.vue'

export default {
  components: {
    WidgetCard
  },
  computed: {
    shorts () {
      return [
        // {
        //   title: 'Фотки',
        //   route: { name: 'Photos' },
        //   action: null,
        //   icon: 'mdi-camera',
        //   enabled: this.$can('show', 'Photo')
        // },
        // {
        //   title: 'Файлы',
        //   route: { name: 'Files' },
        //   action: null,
        //   icon: 'mdi-file-document',
        //   enabled: this.$can('show', 'Files')
        // }
      ].filter(({ enabled }) => enabled)
    },
    items () {
      return [
        {
          name: CARD_DOCTORS,
          enabled: this.$can('show', 'Doctor'),
        },
        {
          name: CARD_PAGES,
          enabled: this.$can('show', 'Pages')
        },
        {
          name: CARD_POSTS,
          enabled: this.$can('show', 'Post'),
        },
      ].filter(({ enabled }) => enabled)
    }
  }
}
</script>

<style lang="scss" scoped>
.widgets__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 320px auto auto;
  grid-gap: 20px;
}
</style>
